import gql from 'graphql-tag';

const containerDetails = gql`
  fragment ContainerDetails on Container {
    id
    depth
    hasPallet
    height
    isBio
    isPacked
    isRefrigerated
    isStackable
    name
    quantity
    temperature
    weight
    width
  }
`;

export default containerDetails;
