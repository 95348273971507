import styled from 'styled-components';

import { colors, mediaQueryMin, screens } from '../../../../styles/theme';

const GridSplitAside = styled.div`
  // background: linear-gradient(
  //   ${colors.tertiary.light},
  //   ${colors.tertiary.lighter}
  // );
  ${mediaQueryMin(screens.desktop)} {
    border-left: 1px solid ${colors.tertiary.light};
  }
  @media print {
    border: none;
    background: none !important;
    padding: 0 !important;
  }
`;

export default GridSplitAside;
