const loadScript = (url, callback) => {
  const script = document.createElement('script'); // create script tag
  script.type = 'text/javascript';

  // when script state is ready and loaded or complete we will call callback
  if (script.readyState) {
    script.onreadystatechange = () => {
      if ('loaded' === script.readyState || 'complete' === script.readyState) {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url; // load by url
  document.getElementsByTagName('head')[0].appendChild(script); // append to head
};

export default loadScript;
