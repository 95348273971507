import gql from 'graphql-tag';

import deliveryDetails from './fragments/details';

const deliveries = gql`
  query deliveries($filter: JSONObject!) {
    deliveries(filter: $filter) {
      id
      activityId
      containers {
        id
      }
      createdAt
      place {
        id
      }
      updatedAt
    }
  }
`;

const deliveriesRetrieve = gql`
  query deliveriesRetrieve($id: String!) {
    deliveriesRetrieve(id: $id) {
      ...DeliveryDetails
      spaceId
    }
  }

  ${deliveryDetails}
`;

export { deliveries, deliveriesRetrieve };
