import React, { FC } from 'react';

import App from '../components/layout/App';

// eslint-disable-next-line @typescript-eslint/ban-types
const requireAuth =
  <P extends { location: any }>(
    ComposedComponent: FC<P & { user?: any }>,
    options?: {
      admin?: boolean;
      layout?: boolean;
    },
    // eslint-disable-next-line react/display-name
  ): FC<P> =>
  // eslint-disable-next-line react/display-name
  ({ location, ...props }) => {
    const layout = options?.layout || false;

    // if (!auth || !auth.currentUser) {
    //   if (typeof window !== 'undefined') {
    //     navigate(String(process.env.GATSBY_REDIRECT_UNLOGED));
    //   }
    // }

    if (layout) {
      return (
        <App admin={options?.admin}>
          <ComposedComponent {...(props as P)} location={location} />
        </App>
      );
    }

    return <ComposedComponent {...(props as P)} location={location} />;
  };

export default requireAuth;
