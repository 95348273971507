import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-apollo';

import {
  addressesDetails as detailsMutation,
  addressesFindplacefromtext as geocodingMutation,
} from '../../../objects/addresses/mutations';
import PlaceType from '../../../types/Place';
import loadScript from '../../../utils/loadScript';

declare global {
  interface Window {
    google: any;
  }
}

const Map: FC<{
  avoidTolls?: boolean;
  className?: string;
  defaultZoom?: number;
  haslabel?: boolean;
  height?: string;
  isPoint?: boolean;
  optimizeWaypoints?: boolean;
  provideRouteAlternatives?: boolean;
  places: Partial<PlaceType>[];
  setDirectionResult?: (result: any) => void;
  showRoute?: boolean;
  width?: string;
  zoom?: number;
}> = ({
  avoidTolls = false,
  className,
  defaultZoom = 15,
  haslabel = true,
  height = '60vh',
  isPoint = false,
  places,
  optimizeWaypoints = false,
  provideRouteAlternatives = false,
  setDirectionResult,
  showRoute = false,
  width = '100%',
  zoom,
}) => {
  const [geocodingMutationFunc] = useMutation(geocodingMutation);
  const [detailsMutationFunc] = useMutation(detailsMutation);
  const [googlePlaceIsLoading, setGooglePlaceIsLoading] = useState(false);
  const [googlePlaceIsLoaded, setGooglePlaceIsLoaded] = useState(false);
  const [map, setMap] = useState<any>();
  const mapRef = useRef<HTMLDivElement>(null);
  const [coordonnees, setCoordonnees] = useState<{
    [index: string]: {
      formattedAddress?: string;
      latitude?: string;
      longitude?: string;
    };
  }>({});

  useEffect(() => {
    const callback = () => {
      if (window.google) {
        // eslint-disable-next-line no-new
        const newMap = new window.google.maps.Map(mapRef.current, {
          center: { lat: 48.85837009999999, lng: 2.2944813 },
          disableDefaultUI: false,
          // gestureHandling: 'none',
          zoom: 15,
        });
        setMap(newMap);
        setGooglePlaceIsLoaded(true);
      }
    };

    if (!googlePlaceIsLoading) {
      if (!window.google) {
        setGooglePlaceIsLoading(true);
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_PLACES_API}&libraries=places`,
          callback,
        );
      } else {
        callback();
      }
    }
  }, [googlePlaceIsLoading]);

  useEffect(() => {
    const findCoordGPS = async (index: number, address: string) => {
      const result = await geocodingMutationFunc({
        variables: {
          address,
        },
      });

      if (result && !result.errors) {
        if ('OK' === result.data.addressesFindplacefromtext.status) {
          const [{ place_id: placeId }] =
            result.data.addressesFindplacefromtext.candidates;

          const details = await detailsMutationFunc({
            variables: {
              placeId,
            },
          });

          if (details && !details.errors) {
            if ('OK' === details.data.addressesDetails.status) {
              const {
                result: {
                  geometry: {
                    location: { lat, lng },
                  },
                },
              } = details.data.addressesDetails;

              setCoordonnees(oldCoordonnees => {
                const newCoordonnees: {
                  [index: string]: {
                    formattedAddress?: string;
                    latitude?: string;
                    longitude?: string;
                  };
                } = { ...oldCoordonnees };

                newCoordonnees[index] = {
                  ...newCoordonnees[index],
                  latitude: lat,
                  longitude: lng,
                };

                return newCoordonnees;
              });
            }
          }
        }
      }
    };

    if (googlePlaceIsLoaded && window.google) {
      setCoordonnees(() => {
        const newCoordonnees: {
          [index: string]: {
            formattedAddress?: string;
            latitude?: string;
            longitude?: string;
          };
        } = {};
        places.forEach((place, index) => {
          if (!place.latitude || !place.longitude) {
            if (place.formattedAddress) {
              findCoordGPS(index, place.formattedAddress);
            }
          }

          newCoordonnees[index] = {
            formattedAddress: place.formattedAddress,
            latitude: place.latitude,
            longitude: place.longitude,
          };
        });

        return newCoordonnees;
      });

      // setPositions(() => {
      //   const newPositions: any[] = [];
      //   places.forEach(place => {
      //     if (place.latitude && place.longitude) {
      //       const position = new window.google.maps.LatLng(
      //         place.latitude,
      //         place.longitude,
      //       );
      //       newPositions.push(position);
      //     } else if (place.formattedAddress) {
      //       findCoordGPS(place.formattedAddress);
      //     }
      //   });
      //
      //   return newPositions;
      // });
    }
  }, [detailsMutationFunc, geocodingMutationFunc, googlePlaceIsLoaded, places]);

  useEffect(() => {
    const positions: any[] = [];
    Object.keys(coordonnees).forEach(index => {
      const { latitude, longitude } = coordonnees[index];

      if (latitude && longitude) {
        const position = new window.google.maps.LatLng(latitude, longitude);
        positions.push(position);
      }
    });

    const markers: any[] = [];
    let directionsRenderer: any;

    const createMarker = (position: any) => {
      const marker: any = {
        map,
      };
      marker.position = position;

      return new window.google.maps.Marker(marker);
    };

    if (map && positions && positions.length > 0) {
      directionsRenderer = new window.google.maps.DirectionsRenderer();

      if (showRoute && positions.length > 1) {
        const directionsService = new window.google.maps.DirectionsService();

        let destination;
        let origin;
        const waypoints: any[] = [];
        positions.forEach((position, index) => {
          if (0 === index) {
            origin = {
              location: {
                lat: position.lat(),
                lng: position.lng(),
              },
            };
          } else if (positions.length - 1 === index) {
            destination = {
              location: {
                lat: position.lat(),
                lng: position.lng(),
              },
            };
          } else {
            waypoints.push({
              location: {
                lat: position.lat(),
                lng: position.lng(),
              },
              stopover: true,
            });
          }
        });

        let options: {
          avoidFerries?: boolean;
          avoidHighways?: boolean;
          avoidTolls?: boolean;
          destination?: any;
          optimizeWaypoints?: boolean;
          origin?: any;
          provideRouteAlternatives?: boolean;
          travelMode: any;
          waypoints?: any[];
        } = {
          avoidTolls,
          optimizeWaypoints,
          provideRouteAlternatives,
          travelMode: window.google.maps.TravelMode.DRIVING,
        };

        if (destination && origin) {
          options = { ...options, destination, origin };

          if (waypoints && waypoints.length > 0) {
            options.waypoints = waypoints;
          }

          directionsService.route(options, (result: any, status: any) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              directionsRenderer.setMap(map);
              directionsRenderer.setDirections(result);
              if (setDirectionResult) {
                setDirectionResult(result);
              }
            } else {
              console.error(`error fetching directions ${result}`);
            }
          });
        }
      } else {
        const bounds = new window.google.maps.LatLngBounds();
        positions.forEach(position => {
          // TODO vérifier que les positions ne sont pas les mêmes
          const marker = createMarker(position);
          markers.push(marker);

          const loc = new window.google.maps.LatLng(
            marker.position.lat(),
            marker.position.lng(),
          );
          bounds.extend(loc);
        });

        map.fitBounds(bounds, { bottom: 10, left: 30, right: 30, top: 60 });
        map.panToBounds(bounds);
        if (1 === positions.length && !zoom) {
          map.setZoom(defaultZoom);
        }
        if (zoom) {
          map.setZoom(zoom);
        }
      }
    }

    return () => {
      if (markers) {
        markers.forEach(marker => marker.setMap(null));
      }
      if (directionsRenderer) {
        directionsRenderer.setMap(null);
      }
    };
  }, [
    avoidTolls,
    haslabel,
    isPoint,
    map,
    optimizeWaypoints,
    coordonnees,
    showRoute,
  ]);

  return (
    <div className={className}>
      <div ref={mapRef} style={{ height, width }} />
    </div>
  );
};

// TODO vérifier si les places ont changé
export default memo(Map);
