import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  boxShadow,
  colors,
  font,
  radius,
  spacing,
} from '../../../styles/theme';
import IconLoading from '../../icons/Loading';
import FullPageCenter from '../FullPageCenter';

const Text = styled.div`
  margin-top: ${spacing.xxs};
  color: ${colors.neutral.base};
  font-weight: ${font.weight.medium};
  font-size: ${font.size.s[0]};
`;
const Box = styled.div`
  text-align: center;
  padding: ${spacing.l} ${spacing.xl};
  background: ${colors.light['300']};
  box-shadow: ${boxShadow.base};
  border-radius: ${radius.s};
`;

/**
 * CircularProgress
 *
 * @param {object} props
 * @param {number} props.size
 * @param {string} props.color
 * @param {boolean} props.fullPage
 * @returns {React.ReactElement}
 */

const CircularProgress: FC<{
  size?: number;
  color?: string;
  fullPage?: boolean;
}> = ({ size = 32, color = colors.primary.base, fullPage = false }) => {
  const { t } = useTranslation();

  if (fullPage) {
    return (
      <FullPageCenter>
        <Box className="mt-4 mb-6 flex flex-col items-center">
          <IconLoading color={color} size={48} />
          <Text>{t(`loading`)}</Text>
        </Box>
      </FullPageCenter>
    );
  }

  return <IconLoading color={color} size={size} />;
};

export default CircularProgress;
