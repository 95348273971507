import styled from 'styled-components';

import {
  colors,
  mediaQueryMin,
  screens,
  spacing,
} from '../../../../styles/theme';

export const MainSC = styled.div<{ widthAside?: boolean }>`
  background: ${props =>
    props.widthAside ? colors.light['300'] : 'transparent'};
`;

export const MainBody = styled.div`
  padding: ${spacing.m} 0;
  ${mediaQueryMin(screens.tablet)} {
    padding: ${spacing.xl} 0;
  }

  @media print {
    padding: 0 !important;
  }
`;
