import React, { FC, ReactElement, SyntheticEvent } from 'react';

import ContainerType from '../../types/Container';
import PlaceType from '../../types/Place';
import Item from '../new/Item';

interface ContainerItemProps {
  customInfos?: ReactElement;
  deleteOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  editPathname?: string;
  places?: PlaceType[];
  container: ContainerType;
}

const ContainerItem: FC<ContainerItemProps> = ({
  customInfos,
  deleteOnClick,
  editPathname,
  places,
  container,
}) => (
  <Item
    customInfos={customInfos}
    deleteOnClick={deleteOnClick}
    editPathname={editPathname}
    id={container.id}
    subTitle={`${container.width} x ${container.depth} x ${container.height} cm - ${container.weight} kg`}
    title={container.name}
  />
);

export default ContainerItem;
