import { rem } from 'polished';
import React, { FC } from 'react';
import styled from 'styled-components';

import { header } from '../../../styles/theme';

const FullPageCenterEl = styled.main`
  min-height: calc(100vh - ${rem(header.height.base)});
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

/**
 * FullPageCenter
 *
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @returns {React.ReactElement}
 */
const FullPageCenter: FC = ({ children }) => (
  <FullPageCenterEl>{children}</FullPageCenterEl>
);

export default FullPageCenter;
