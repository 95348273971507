import gql from 'graphql-tag';

const deliveriesCreate = gql`
  mutation deliveriesCreate(
    $activityId: GUID!
    $duration: Float
    $isPresenceRequired: Boolean
    $placeId: GUID
  ) {
    deliveriesCreate(
      activityId: $activityId
      duration: $duration
      isPresenceRequired: $isPresenceRequired
      placeId: $placeId
    ) {
      id
    }
  }
`;

const deliveriesDelete = gql`
  mutation deliveriesDelete($id: GUID!) {
    deliveriesDelete(id: $id)
  }
`;

const deliveriesUpdate = gql`
  mutation deliveriesUpdate(
    $duration: Float
    $id: GUID!
    $isPresenceRequired: Boolean
    $placeId: GUID
  ) {
    deliveriesUpdate(
      duration: $duration
      id: $id
      isPresenceRequired: $isPresenceRequired
      placeId: $placeId
    ) {
      id
    }
  }
`;

export { deliveriesCreate, deliveriesDelete, deliveriesUpdate };
