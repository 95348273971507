import { Unsubscribe } from '@firebase/firestore';
import { Form, submit, useDispatch } from 'dataformjs';
import dayjs from 'dayjs';
import { navigate, PageProps } from 'gatsby';
import compact from 'lodash/compact';
import React, { FC, useEffect } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import Button from '../../../../components/base/Button';
import CircularProgress from '../../../../components/base/CircularProgress';
import GridSplit from '../../../../components/base/GridSplit';
import DeliveryContainers from '../../../../components/Delivery/Containers';
import IconAdd from '../../../../components/icons/Add';
import IconDelete from '../../../../components/icons/Delete';
import IconSave from '../../../../components/icons/Save';
import AsideBody from '../../../../components/Space/Aside/Body';
import Collection from '../../../../models/Collection';
import deliveryData from '../../../../objects/deliveries/datas.json';
import {
  deliveriesDelete as deleteMutation,
  deliveriesUpdate as UpdateMutation,
} from '../../../../objects/deliveries/mutations';
import { deliveriesRetrieve as retrieveQuery } from '../../../../objects/deliveries/queries';
import DeliveryType from '../../../../types/Delivery';
import requiredAuth from '../../../../utils/requireAuth';

const DeliveryUpdatePage: FC<PageProps> = ({ params: { id } }) => {
  const formName = 'delivery';
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [deleteMutationFunc] = useMutation(deleteMutation);
  const [updateMutationFunc] = useMutation(UpdateMutation);
  const { data, error, loading, refetch } = useQuery(retrieveQuery, {
    fetchPolicy: 'network-only',
    variables: {
      id,
    },
  });

  useEffect(() => {
    let unsub: Unsubscribe;
    if (id && refetch) {
      if (typeof window !== `undefined`) {
        unsub = Collection.onSnapshot('delivery', id, snapshot => {
          if (!snapshot.exists()) {
            return Collection.initDoc('delivery', id);
          }
          console.info('refetch delivery', id);

          return refetch();
        });
      }
    }

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [id, refetch]);

  const delivery: DeliveryType =
    data && (data.deliveriesRetrieve as DeliveryType);

  if (loading) {
    return <CircularProgress fullPage />;
  }

  if (error) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  }

  if (!delivery) {
    return <div>La livraison n&aspo;existe pas</div>;
  }

  const handleSaveOnClick = () => {
    dispatch(submit(formName));
  };

  const handleOnSubmit = async (values: any) => {
    await updateMutationFunc({
      variables: {
        id,
        duration: values.duration && Number(values.duration),
        isPresenceRequired: Boolean(values.isPresenceRequired),
        placeId: values.place?.id,
      },
    });

    refetch();
  };

  const handleDeleteOnClick = async () => {
    const ok = window.confirm(
      'Etes-vous sûr de vouloir supprimer cet livraison ?',
    );

    if (ok) {
      const result = await deleteMutationFunc({
        variables: {
          id: delivery.id,
        },
      });

      if (!result || result.errors) {
        console.error(result.errors);
      } else {
        // Mise à jour de la livraison dans firestore
        const date = dayjs().toISOString();
        await Collection.updateDoc('delivery', delivery.id, {
          deleted: true,
          deletedAt: date,
          updatedAt: date,
        });
        await Collection.updateDoc('activity', delivery.activityId, {
          updatedAt: date,
        });

        navigate(-1);
      }
    }
  };

  return (
    <GridSplit
      aside={{
        body: (
          <AsideBody
            places={compact([delivery.activity?.place, delivery.place])}
            showRoutes
          />
        ),
        className: 'sticky top-0 self-start',
        width: '50%',
      }}
      header={{
        actions: (
          <div className="flex space-x-3">
            <Button
              iconRight={IconAdd}
              size="small"
              status="secondary"
              to={`/admin/containers/create/?delivery=${delivery.id}`}
            >
              {t('deliveries.update.actions.addContainer')}
            </Button>
            <Button
              iconLeft={IconSave}
              onClick={handleSaveOnClick}
              size="small"
              title={t('deliveries.update.actions.submit')}
            />
            <Button
              iconLeft={IconDelete}
              onClick={handleDeleteOnClick}
              size="small"
              status="danger"
              title={t('deliveries.update.actions.deleted')}
            />
          </div>
        ),
        back: {
          onClick: () => {
            window.history.back();
          },
          text: t(`back`),
        },
        title: delivery.place?.name,
      }}
    >
      {loading && <CircularProgress />}
      <Form
        className="mx-6 mb-6"
        datas={deliveryData}
        hideSubmitButton
        initialValues={delivery}
        name={formName}
        onSubmit={handleOnSubmit}
        params={{
          activityId: delivery.activityId,
          spaceId: delivery.spaceId,
        }}
        submitProps={{
          label: t(`delivery.form.submit.label`),
          size: 'small',
        }}
      />
      <DeliveryContainers
        className="mx-6 flex flex-col space-y-6"
        delivery={delivery}
      />
    </GridSplit>
  );
};

export default requiredAuth(DeliveryUpdatePage, {
  admin: true,
  layout: true,
});
