import { DocumentSnapshot, Unsubscribe, UpdateData } from '@firebase/firestore';
import dayjs from 'dayjs';
import { doc, getDoc, onSnapshot, setDoc, updateDoc } from 'firebase/firestore';

import { firestore } from '../firebase';

class Collection {
  static getDoc(collectionName: string, id: string): Promise<DocumentSnapshot> {
    const ref = doc(firestore, collectionName, id);

    return getDoc(ref);
  }

  static initDoc(collectionName: string, id: string): Promise<void> {
    const ref = doc(firestore, collectionName, id);

    const date = dayjs().toISOString();

    return setDoc(ref, {
      createdAt: date,
      updatedAt: date,
    });
  }

  static onSnapshot(
    collectionName: string,
    id: string,
    next: (snapshot: DocumentSnapshot) => void,
  ): Unsubscribe {
    const ref = doc(firestore, collectionName, id);

    return onSnapshot(ref, next);
  }

  static updateDoc(
    collectionName: string,
    id: string,
    values: UpdateData<any>,
  ): Promise<void> {
    const ref = doc(firestore, collectionName, id);

    return updateDoc(ref, values);
  }
}

export default Collection;
