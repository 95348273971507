import React, { FC, memo } from 'react';

import PlaceType from '../../../types/Place';
import Map from '../../base/Map';

interface AsideBodyProps {
  optimizeWaypoints?: boolean;
  places: Partial<PlaceType>[];
  showRoutes?: boolean;
  zoom?: number;
}
const AsideBody: FC<AsideBodyProps> = ({
  optimizeWaypoints,
  places,
  showRoutes,
  zoom,
}) => {
  const handleSetDirectionResult = (result: any) => {
    console.info('directionsService', result);

    // const [{ legs, waypoint_order: waypointOrder }] = result.routes;
    // let totalDistance = 0;
    // let totalDuration = 0;
    // legs.forEach((leg: any) => {
    //   const {
    //     distance: { value: legDistance },
    //     duration: { value: legDuration },
    //   } = leg;
    //   totalDistance += legDistance;
    //   totalDuration += legDuration;
    // });
    // setDistance(totalDistance);
    // setDuration(totalDuration);
    // console.info('waypointOrder', waypointOrder);
  };

  return (
    <div className="relative">
      <Map
        avoidTolls={false}
        height="calc(100vh - 80px)"
        optimizeWaypoints={optimizeWaypoints}
        places={places as PlaceType[]}
        provideRouteAlternatives
        setDirectionResult={handleSetDirectionResult}
        showRoute={showRoutes}
        zoom={zoom}
      />
    </div>
  );
};

export default memo(AsideBody);
