import gql from 'graphql-tag';

import containerDetails from '../../containers/fragments/details';
import placeDetails from '../../places/fragments/details';

const deliveryDetails = gql`
  fragment DeliveryDetails on Delivery {
    id
    activityId
    containers {
      ...ContainerDetails
    }
    createdAt
    duration
    place {
      ...PlaceDetails
    }
    updatedAt
  }

  ${containerDetails}
  ${placeDetails}
`;

export default deliveryDetails;
