import React, { FC, ReactNode, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import IconBack from '../../../../icons/Back';
import Button from '../../../Button';
import Container from '../../../Container';
import Title from '../../../Title';
import { BackLink, Breadcrumb, Step, WrapperEl } from './styles';

export interface GridSplitMainHeaderProps {
  actions?: ReactNode;
  back?: {
    text: string;
    to?: string;
    onClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  };
  className?: string;
  infos?: ReactNode;
  step?: string;
  nav?: ReactNode;
  subtitle?: string;
  title: ReactNode;
}

const GridSplitMainHeader: FC<GridSplitMainHeaderProps> = ({
  actions,
  back,
  className,
  infos,
  step,
  nav,
  subtitle,
  title,
}) => {
  const { t } = useTranslation();

  return (
    <WrapperEl className={className}>
      <Container>
        <div className="py-4 md:py-5 md:flex md:content-center md:justify-between">
          <div>
            {back && back.to && (
              <BackLink className="mb-2 no-print" to={back.to}>
                <IconBack size={12} />
                <span>{back.text}</span>
              </BackLink>
            )}
            {back && back.onClick && (
              <Button
                iconLeft={IconBack}
                iconSize={12}
                onClick={back.onClick}
                status="link"
              >
                <span>{back.text}</span>
              </Button>
            )}
            {subtitle && <div className="mb-2">{subtitle}</div>}
            <Breadcrumb>
              {'string' === typeof title ? (
                <Title className="text-xl">{t(title)}</Title>
              ) : (
                title
              )}

              {step && <Step className="no-print mt-1">{t(step)}</Step>}
            </Breadcrumb>
            {infos && <div className="mt-1">{infos}</div>}
          </div>
          {actions && <div className="mt-4 md:mt-0 md:ml-4">{actions}</div>}
        </div>
        {nav}
      </Container>
    </WrapperEl>
  );
};

export default GridSplitMainHeader;
