import { rem } from 'polished';
import styled from 'styled-components';

import {
  colors,
  font,
  mediaQueryMax,
  screens,
  spacing,
} from '../../../../../styles/theme';
import Link from '../../../Link';

export const WrapperEl = styled.div`
  background: ${colors.light['300']};
  border-bottom: 1px solid ${colors.tertiary.lighter};
  ${mediaQueryMax(screens.navigation)} {
    > * + * {
      margin-top: ${spacing.s};
    }
  }
  @media print {
    padding: 0 0 5mm;
    border: none;
  }
`;

export const Breadcrumb = styled.div``;

export const Step = styled.div`
  font-weight: ${font.weight.medium};
  color: ${colors.neutral.base};
`;

export const BackLink = styled(Link)`
  line-height: 1;
  display: inline-grid;
  grid-gap: ${rem(6)};
  grid-template-columns: ${rem(12)} 1fr;
  align-items: center;
  text-transform: uppercase;
  text-decoration: none;
  font-size: ${rem(12)};
  letter-spacing: ${rem(1)};
  font-weight: ${font.weight.medium};
`;
