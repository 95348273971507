import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import DeliveryType from '../../../types/Delivery';
import ContainerItem from '../../item/Container';

interface DeliveryContainersProps {
  className?: string;
  delivery: DeliveryType;
}

const DeliveryContainers: FC<DeliveryContainersProps> = ({
  className,
  delivery,
}) => {
  const { t } = useTranslation();

  if (!delivery.containers || 0 === delivery.containers.length) {
    return <div className={className}>{t('delivery.containers.empty')}</div>;
  }

  return (
    <div className={className}>
      <h2 className="text-lg font-bold">{t('delivery.containers.title')}</h2>
      {delivery.containers.map(container => (
        <ContainerItem
          key={container.id}
          container={container}
          editPathname="/admin/containers/update/"
        />
      ))}
    </div>
  );
};

export default DeliveryContainers;
