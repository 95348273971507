import { rem } from 'polished';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import {
  colors,
  header as styleHeader,
  mediaQueryMin,
  screens,
} from '../../../styles/theme';
import GridSplitAside from './aside';
import GridSplitMain from './main';

const GridSplitSC = styled.div<{
  alternate?: boolean;
  widthAside?: string;
  withAside?: boolean;
}>`
  background: ${props =>
    props.alternate ? colors.light['300'] : 'transparent'};
  ${mediaQueryMin(screens.laptop)} {
    min-height: calc(100vh - ${rem(styleHeader.height.base)});
    display: grid;
    grid-template-columns: ${props =>
      props.withAside ? `1fr ${props.widthAside || '500px'}` : '1fr'};
  }

  @media print {
    display: block;
  }
`;

const GridSplit: FC<{
  alternate?: boolean;
  aside?: { className?: string; body: ReactNode; width?: string };
  header?: any;
}> = ({ alternate = false, aside, children, header }) => (
  <GridSplitSC
    alternate={alternate}
    widthAside={aside?.width}
    withAside={!!aside}
  >
    <GridSplitMain header={header} widthAside={!!aside}>
      {children}
    </GridSplitMain>
    {aside && (
      <GridSplitAside
        className={aside.className || 'pt-4 md:pt-6 sticky top-0 self-start'}
      >
        {aside.body}
      </GridSplitAside>
    )}
  </GridSplitSC>
);

export default GridSplit;
