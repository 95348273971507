import React, { FC } from 'react';

import GridSplitMainHeader, { GridSplitMainHeaderProps } from './header';
import { MainBody, MainSC } from './styles';

const GridSplitMain: FC<{
  header?: GridSplitMainHeaderProps;
  widthAside?: boolean;
}> = ({ children, header, widthAside = false }) => (
  <MainSC widthAside={widthAside}>
    {header && <GridSplitMainHeader {...header} />}
    <MainBody>{children}</MainBody>
  </MainSC>
);

export default GridSplitMain;
